import PersonCard from '../PersonCard/PersonCard';
import './MainPerson.css';

import brett_mitchell from '../../../assets/images/Team/Members/brett_mitchell.webp'

function MainPerson() {
    return (
        <div className='main-person'>
            <div className='main-person__decor'> </div>
            <div className='main-person__container'>
                <div className='main-person__info'>
                    <p className='main-person__info-text'>With decades of&nbsp;combined experience in&nbsp;finance, stockbroking, investment advisories and corporate finance, our team brings a&nbsp;superior level of&nbsp;financial expertise to&nbsp;each company in&nbsp;our portfolio.</p>
                </div>
                <div className='main-person__card'>
                    <PersonCard item={{
                        photo: brett_mitchell,
                        name: 'Brett Mitchel',
                        job: 'Managing Partner',
                        // text: 'Lorem ipsum dolor sit amet consectetur. Dui vitae vel turpis convallis vivamus gravida aliquet pretium platea. Vestibulum malesuada adipiscing et&nbsp;elementum at&nbsp;aliquam enim.',
                    }}
                        isMain={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default MainPerson