import { OUR_BUISNESS_CARDS } from '../../../assets/utils/constants';
import './OurBusiness.css';



function OurBusiness({ servicesPage, className }) {
    return (
        <div className={`our-business ${className ? className : ''}`}>
            {servicesPage ?
                null
                :
                <div>
                    <div className='our-business__heading'>
                        <p className='our-business__subtitle'>SPUTNIK team specializes in&nbsp;identifying distinctive early-stage businesses that need corporate finance expertise or&nbsp;equity capital. We&nbsp;support these ventures in&nbsp;developing and expanding their assets, enabling them to&nbsp;reach their goals and achieve sustainable growth</p>
                        <p className='our-business__subtitle our-business__subtitle_second'>Venture Capital&nbsp;&mdash; We&nbsp;not just investing in&nbsp;your business, we&nbsp;become your committed partner, we&nbsp;provide opportunities for financing to&nbsp;take your business to&nbsp;the next level, and ensure new investors match your profile perfectly.</p>

                    </div>
                    <p className='our-business__paragraph'>Investment &amp;&nbsp;Distribution change to&nbsp;Diversified and Network&nbsp;&mdash; Our extensive corporate network and business contacts provide access to&nbsp;a&nbsp;diverse range of&nbsp;high-net-worth investors and industry leaders. This network delivers investment opportunities covering a&nbsp;wide range of&nbsp;industries and business opportunities network to&nbsp;our partners</p>
                </div>

            }
            <div className={`our-business__cards ${servicesPage ? 'our-business__cards_service-page' : ''}`}>
                <div className={`our-business__card our-business__card_type_1`}>
                    <p className='our-business__card-number'>01</p>
                    {/* 
                        <h5 className='our-business__card-title'>{item.title}</h5> */}
                    <p className='our-business__card-subtitle' dangerouslySetInnerHTML={{
                        __html: `Sputnik team assisting in&nbsp;the capital and commercial strategy development of&nbsp;the company, from capital markets and operations Sputnik provides board advisory services down to&nbsp;business development and operational consulting services.`,
                    }}></p>
                </div>
                <div className={`our-business__card our-business__card_type_2`}>
                    <p className='our-business__card-number'>02</p>
                    {/* 
                        <h5 className='our-business__card-title'>{item.title}</h5> */}
                    <p className='our-business__card-subtitle' dangerouslySetInnerHTML={{
                        __html: `We&nbsp;offer one stop shop for our clients to&nbsp;get their company to&nbsp;the next stage, from helping to&nbsp;build the business plan, company investment presentations and portfolio to&nbsp;support in&nbsp;raising the capital and corporate advisory to&nbsp;assist in&nbsp;the company growth.`,
                    }}></p>
                </div>
            </div>
        </div>
    );
}

export default OurBusiness