import { Card1Icon, Card2Icon, Card3Icon, Card4Icon } from '../../../assets/icons/icons';
import './Financial.css';

const cards = [
    {
        title: `Private 
equity funding`,
        icon: Card1Icon,
    },
    {
        title: `Pre-IPO 
Capital`,
        icon: Card2Icon,
    },
    {
        title: `Investor 
Relations`,
        icon: Card3Icon,
    },

    {
        title: `Reverse 
Takeovers`,
        icon: Card4Icon,
    },
//     {
//         title: `IPO's`,

//     },
//     {
//         title: `Mergers 
// and Acquisitions`,

//     },
//     {
//         title: `Management & 
// Board Composition`,

//     },
//     {
//         title: `Placements 
// and Prospectus`,

//     },
//     {
//         title: `Branding and 
// Company Presentation`,

//     },
//     {
//         title: `Business 
// Networking`,

//     },
]

function Financial() {
    return (
        <div className='financial'>
            <div className='financial__heading'>
                <h5 className='financial__title'>Financial <br />advisory</h5>
                <p className='financial__subtitle'>With decades of&nbsp;combined experience in&nbsp;finance, stockbroking, investment advisories and corporate finance, our team brings a&nbsp;superior level of&nbsp;financial expertise to&nbsp;each company in&nbsp;our portfolio.</p>
            </div>

            <div className='financial__cards'>
                {cards.map((item, i) => (
                    <div className='financial__card'>
                        <p className='financial__card-number'>{i + 1}</p>
                        {item.icon ? item.icon({className: 'financial__card-icon'}) : <div className='financial__card-icon'></div>}
                        <p className='financial__card-title'>{item.title}</p>
                    </div>
                ))}

            </div>
        </div>
    );
}

export default Financial