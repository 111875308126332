import './TeamHeading.css';

import helmet from '../../../assets/images/Team/Heading/helmet.webp'
import { StarIcon } from '../../../assets/icons/icons';

function TeamHeading() {
    return (
        <div className='team-heading'>
            <div className='team-heading__bg-circle-1'></div>
            <div className='team-heading__bg-circle-2'></div>
            <div className='team-heading__helmet-box'>
                <img className='team-heading__helmet-img' src={helmet} alt=''></img>
                <img className='team-heading__helmet-fade' src={helmet} alt=''></img>
            </div>
            <div className='team-heading__text-box'>
                <StarIcon className={'team-heading__text-star'} />
                <h4 className='team-heading__text-main'>Team</h4>
                <span className='team-heading__text-fade'>Team</span>

            </div>

        </div>
    );
}

export default TeamHeading