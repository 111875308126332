import News from '../News/News';
import Banner from './Banner/Banner';
import './Main.css';
import Numbers from './Numbers/Numbers';
import NumbersSeparator from './NumbersSeparator/NumbersSeparator';
import OurBusiness from './OurBusiness/OurBusiness';
import Projects from './Projects/Projects';
import Recent from './Recent/Recent';

function Main() {
  return (
    <div className='main'>
      <div className='main__bg-fade-1'></div>
      <div className='main__bg-circle-1'></div>

      <Banner />
      <Numbers />
      <NumbersSeparator />
      <OurBusiness />
     
      <Recent />
      <Projects />
      {/* <News className='main__news' /> */}
    </div>
  );
}

export default Main