import './Management.css';

import bg from '../../../assets/images/Services/Managment/managment-bg.webp'

function Management() {
    //  {
    //         title: `IPO's`,

    //     },
    //     {
    //         title: `Mergers and Acquisitions`,

    //     },
    //     {
    //         title: `Management & 
    // Board Composition`,

    //     },
    //     {
    //         title: `Placements 
    // and Prospectus`,

    //     },
    //     {
    //         title: `Branding and 
    // Company Presentation`,

    //     },
    //     {
    //         title: `Business 
    // Networking`,

    //     },

    return (
        <div className='management'>
            <img className='management__bg' src={bg} alt=''></img>
            <p className='management__card management__card_1'>IPO's</p>
            <p className='management__card management__card_2'>Mergers <br />and Acquisitions</p>
            <p className='management__card management__card_3'>Management <br/>& Board Composition</p>
            <p className='management__card management__card_4'>Placements <br />and Prospectus</p>
            <p className='management__card management__card_5'>Branding <br />and Company Presentation</p>
            <p className='management__card management__card_6'>Business <br />Networking</p>
        </div>
    );
}

export default Management