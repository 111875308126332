import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper";

import useWindowSize from '../../../assets/hooks/useWindowSize';
import { MgcLogoIcon, SatelliteIcon } from '../../../assets/icons/icons';
import './Projects.css';

import "swiper/css";
import "swiper/css/pagination";
import { useState } from "react";
import { Link } from "react-router-dom";

import graft_logo from '../../../assets/images/Portfolio/logo/Graft.png'
import sas_logo from '../../../assets/images/Portfolio/logo/sas.png'
import acv_logo from '../../../assets/images/Portfolio/logo/acv.png'
import ts_logo from '../../../assets/images/Portfolio/logo/ts.png'

const PROJECTS_CARDS = [
    {
        date: '',
        title: 'MGC pharma',
        text: 'A next-generation pharmaceutical company',
        icon: MgcLogoIcon,
        isLogoSvg: true,
        link: 'https://mgcpharma.co.uk',
    },
    {
        date: '',
        title: 'Graft Polymer',
        text: 'Graft Polymer (UK) Plc (LSE: GPL) was established in 2017 by a group of polymer technology experts and venture capitalists. ',
        icon: graft_logo,
        link: 'https://graftpolymer.com',
    },
    {
        date: '',
        title: 'Sky and Space',
        text: 'SAS is a satellite company with communication nanosatellites and software expertise. Providing communications infrastructure and services,  SAS’s nanosatellites and sophisticated software systems support space-based global communication networks.',
        icon: sas_logo,
        link: 'https://skyandspace.co',
    },
    {
        date: '',
        title: 'Australian Cannabis Ventures',
        text: 'Organic indoor cannabis farm. High quality medical and recreational cannabis with fast and discreet delivery.',
        icon: acv_logo,
        link: 'https://www.acv.net.au',
    },
    {
        date: '',
        title: 'Token States',
        text: 'Token States is a blockchain-powered real estate asset tokenization platform whose aim is to provide an opportunity to invest in real estate as easily as possible.',
        icon: ts_logo,
        link: 'https://tokenstates.io',
    },
]

function Projects() {
    const [my_swiper, set_my_swiper] = useState({});

    const windowSize = useWindowSize()
    const cards_sliced = windowSize.width <= 1150 ? PROJECTS_CARDS.slice(0, 3) : PROJECTS_CARDS
    return (
        <div className='projects'>
            {cards_sliced.map((item, i) => (
                <a href={item.link} target="_blank" rel="noreferrer" className='projects__card projects__card_pc'>
                    <div className='projects__card-heading'>
                        <div className='projects__card-heading-info'>
                            <p className='projects__card-date'>{item.date ? item.date : ` `}</p>
                            <p className='projects__card-title'>{item.title}</p>
                        </div>
                        {item.isLogoSvg ? item.icon({ className: 'projects__card-icon' }) : <img className="projects__card-icon" src={item.icon} alt=""></img>}
                    </div>
                    <p className='projects__card-text' dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}></p>
                </a>
            ))}
            <div className='projects__last-card projects__card_pc'>
                <SatelliteIcon className={'projects__satellite-icon'} />
                <Link className='projects__btn' to={'/portfolio'}>
                    All Projects
                </Link>
            </div>
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 1,
                }}
                mousewheel={{
                    forceToAxis: true,
                }}

                modules={[Mousewheel, Pagination]}
                preventInteractionOnTransition={true}
                className="projects__cards projects__cards_mobile"
                onInit={(ev) => {
                    set_my_swiper(ev)
                }}

            >
                {PROJECTS_CARDS.map((item, i) => (
                    <SwiperSlide className={`projects__card `} key={`projects__card-slider__card${i}`}>
                        <a href={item.link} target="_blank" rel="noreferrer" >

                            <div className='projects__card-heading'>
                                <div className='projects__card-heading-info'>
                                    <p className='projects__card-date'>{item.date ? item.date : ` `}</p>
                                    <p className='projects__card-title'>{item.title}</p>
                                </div>
                                {item.isLogoSvg ? item.icon({ className: 'projects__card-icon' }) : <img className="projects__card-icon" src={item.icon} alt=""></img>}
                            </div>
                            <p className='projects__card-text' dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}></p>
                        </a>

                    </SwiperSlide>
                ))}

                <SwiperSlide className='projects__last-card'>
                    <SatelliteIcon className={'projects__satellite-icon'} />
                    <Link className='projects__btn' to={'/portfolio'}>
                        All Projects
                    </Link>
                </SwiperSlide>


            </Swiper>
        </div>
    );
}

export default Projects