import './Numbers.css';

function Numbers() {
    return (
        <div className='numbers'>
            <div className='numbers__card'>
                <p className='numbers__card-title'>Funds Raise in £m</p>
                <p className='numbers__card-number'>200</p>
            </div>
            <div className='numbers__separator'></div>
            <div className='numbers__card'>
                <p className='numbers__card-title'>Companies in portfolio</p>
                <p className='numbers__card-number'>10</p>
            </div>
            <div className='numbers__separator'></div>
            <div className='numbers__card'>
                <p className='numbers__card-title'>Companies IPOed</p>
                <p className='numbers__card-number'>4</p>
            </div>
            <div className='numbers__separator'></div>
            <div className='numbers__card'>
                <p className='numbers__card-title'>Years on market</p>
                <p className='numbers__card-number'>5</p>
            </div>
        </div>
    );
}

export default Numbers