import './PersonCard.css';

function PersonCard({ item, isMain }) {
    // photo: 'https://i.ibb.co/XCQqvwf/Rectangle-159.png',
    // name: 'Sheri MacGyver',
    // job: 'CEO & CO-FOUNDER',
    // text: 'Lorem ipsum dolor sit amet consectetur. Dui vitae vel turpis convallis vivamus gravida aliquet pretium platea. Vestibulum malesuada adipiscing et&nbsp;elementum at&nbsp;aliquam enim.',

    return (
        <>
            {item.link ?
                <a href={item.link} target="_blank" rel="noreferrer" className={`person-card ${isMain ? 'person-card_main' : ''}`}>
                    <div className='person-card__photo-and-name'>
                        <img className={`person-card__photo `} src={item.photo} alt='' ></img>
                        <div className='person-card__name-and-job'>
                            <p className={`person-card__name`}>{item.name}</p>
                            <p className='person-card__job'>{item.job}</p>
                        </div>
                    </div>
                    <p className='person-card__text' dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}></p>
                </a>
                :
                <div className={`person-card ${isMain ? 'person-card_main' : ''}`}>
                    <div className='person-card__photo-and-name'>
                        <img className={`person-card__photo `} src={item.photo} alt='' ></img>
                        <div className='person-card__name-and-job'>
                            <p className={`person-card__name`}>{item.name}</p>
                            <p className='person-card__job'>{item.job}</p>
                        </div>
                    </div>
                    <p className='person-card__text' dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}></p>
                </div>
            }
        </>

    );
}

export default PersonCard