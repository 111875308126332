export const MAIN_LINKS = [
    {
        name: 'PORTFOLIO',
        path: 'portfolio',
    },
    {
        name: 'SERVICES',
        path: 'services',
    },
    {
        name: 'TEAM',
        path: 'team',
    },
]

export const OUR_BUISNESS_CARDS = [
    {
        title: `Corporate and Capital market advisory firm`,
        subtitle: `SPUTNIK works closely with teams to&nbsp;provide the financial, operational and strategic support required to&nbsp;bring businesses from concept to&nbsp;market deployment, commercialisation and beyond.`,
    },
    {
        title: `Assisting in raising capital from seed rounds to public capital raise for mature companies.
        `,
        subtitle: `SPUTNIK works closely with teams to&nbsp;provide the financial, operational and strategic support required to&nbsp;bring businesses from concept to&nbsp;market deployment, commercialisation and beyond.`,
    },
    {
        title: `Supporting Investor Relation and Public Markets strategies`,
        subtitle: `SPUTNIK works closely with teams to&nbsp;provide the financial, operational and strategic support required to&nbsp;bring businesses from concept to&nbsp;market deployment, commercialisation and beyond.`,
    },
]