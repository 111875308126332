import { Link } from 'react-router-dom';
import { ArrowIcon, LogoIcon } from '../../assets/icons/icons';
import './Footer.css';
import { MAIN_LINKS } from '../../assets/utils/constants';
import useWindowSize from '../../assets/hooks/useWindowSize';

const SECONDARY_LINKS = [
    {
        name: '+44 852 368-45-21',
        link: 'tel:+448523684521',
    },
    {
        name: 'info@sputnikenterprises.co.uk',
        link: 'mailto:info@sputnikenterprises.co.uk',
    },
    {
        name: `The Queen's Walk London, SE1 2AA United Kingdom`,
        link: '',
    },
]

function Footer() {
    // const windowSize = useWindowSize()
    // const links = [...MAIN_LINKS,
    // {
    //     name: windowSize.width <= 880 ? 'CONSULTATION' : 'BOOK A CONSULTATION',
    //     path: '/book'
    // }]

    function onClickScroll() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }
    return (
        <footer className='footer'>
            <div className='footer__heading'>
                <Link to={'/'}>
                    <LogoIcon className={'footer__logo'} />
                </Link>
                <div className='footer__links'>
                    <div className='footer__main-links'>
                        {MAIN_LINKS.map((item, i) => (
                            <>
                                <Link
                                    className={'footer__main-link'}
                                    key={`footer__main-link${i}`}
                                    to={`/${item.path}`}>
                                    {item.name}
                                </Link>
                                {i + 1 !== MAIN_LINKS.length ? <div className='footer__main-link-separator'></div> : null}
                            </>
                        ))}
                    </div>
                    <div className='footer__secondary-links'>
                        {SECONDARY_LINKS.map((item, i) => (
                            <>
                                {item.link ?
                                    <a
                                        className={'footer__secondary-link'}
                                        key={`footer__secondary-link${i}`}
                                        href={`${item.link}`}>
                                        {item.name}
                                    </a> :
                                    <p className={'footer__secondary-link'}
                                        key={`footer__secondary-link${i}`}>
                                        {item.name}
                                    </p>
                                }
                                {i + 1 !== SECONDARY_LINKS.length ? <div className='footer__secondary-link-separator'></div> : null}
                            </>
                        ))}
                    </div>
                </div>
            </div>
            <div className='footer__circle'></div>
            <div className='footer__btn-circle'>
                <button className='footer__btn' type='button' onClick={onClickScroll}>
                    <ArrowIcon className={'footer__btn-arrow'} />
                </button>
            </div>
            <p className='footer__copyright'>© Sputnik. All Right Received – 2023 </p>
        </footer>
    );
}

export default Footer