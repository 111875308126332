import MainPerson from './MainPerson/MainPerson';
import Presons from './Presons/Presons';
import './Team.css';
import TeamHeading from './TeamHeading/TeamHeading';
import space from '../../assets/images/Main/Banner/main-banner-bg.png'
function Team() {
  return (
    <div className='team'>
      <div className='team__bg-circle-1'></div>
      <div className='team__bg-circle-2'></div>

      <TeamHeading />
      <MainPerson />
      <Presons />
      <img className='team__space-img' src={space} alt=''></img>
    </div>
  );
}

export default Team