import './Recent.css';

import coin from '../../../assets/images/Main/Recent/recent-coin.webp'

function Recent() {
    return (
        <div className='recent'>
            <img className='recent__coin' src={coin} alt='' />
            <p className='recent__title'>Recent projects</p>
            <p className='recent__subtitle'>Sputnik Enterprises provides access to&nbsp;seed and early stage funding to&nbsp;support the development and growth of&nbsp;both listed and unlisted companies</p>
        </div>
    );
}

export default Recent