import { Link, NavLink } from 'react-router-dom';
import './Header.css';
import { LogoIcon } from '../../assets/icons/icons';
import { MAIN_LINKS } from '../../assets/utils/constants';

function Header() {
    return (
        <header className='header'>
            <Link className='header__logo-box' to={'/'}>
                <LogoIcon className={'header__logo'} />
            </Link>
            <nav className='header__links'>
                {MAIN_LINKS.map((item, i) => (
                    <>
                        <NavLink
                            className={({ isActive }) =>
                                "header__link" + (isActive ? " header__link_active" : "")
                            }
                            key={`HeaderLinks_link${i}`}
                            to={`/${item.path}`}>
                            {item.name}
                        </NavLink>
                        {i + 1 !== MAIN_LINKS.length ? <div className='header__link-separator'></div> : null}
                    </>
                ))}
            </nav>
        </header>
    );
}

export default Header