
import { Route, Routes } from 'react-router-dom';
import Header from '../Header/Header';
import './App.css';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import Portfolio from '../Portfolio/Portfolio';
import Services from '../Services/Services';
import Team from '../Team/Team';

function App() {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path='/' index element={<Main />}></Route>
        <Route path='/portfolio' element={<Portfolio />}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/team' element={<Team />}></Route>

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
