import OurBusiness from '../Main/OurBusiness/OurBusiness';
import OurServices from './OurServices/OurServices';
import './Services.css';

import sputnik_img from '../../assets/images/Services/services-sputnic.webp'
import sputnik_img_mobile from '../../assets/images/Services/services-sputnic-mobile.webp'
import useWindowSize from '../../assets/hooks/useWindowSize';
import Financial from './Financial/Financial';
import Management from './Management/Management';

import moon from '../../assets/images/Services/moon.webp'



function Services() {
    const windowSize = useWindowSize()
    return (
        <div className='services'>
            <div className='services__bg-circle'></div>
            <img className='services__bg-moon' src={moon} alt=''></img>
            <div className='services__bg-circle-2'></div>
            <OurServices />
            <OurBusiness servicesPage={true} className={'services__our-buisness'} />
            <img className='services__img' src={windowSize.width <= 650 ? sputnik_img_mobile : sputnik_img} alt=''></img>
            <Financial />
            <Management />
        </div>
    );
}

export default Services