import './Portfolio.css';

import heading_coin from '../../assets/images/Portfolio/Heading/coin-orbit.webp'
import PortfolioCards from './PortfolioCards/PortfolioCards';

function Portfolio() {
    return (
        <div className='portfolio'>
            <div className='portfolio__heading'>
                <div className='portfolio__heading-main'>
                    <h3 className='portfolio__heading-tilte'>OUR <br />PORTFOLIO</h3>
                    <p className='portfolio__heading-subtilte'>Below is an overview <br />of the companies in our portfolio</p>
                </div>
                <div className='portfolio__heading-numbers'>
                    <p className='portfolio__heading-number'>10</p>
                    <p className='portfolio__heading-number-subtitle'>Successful Investment Projects</p>
                </div>
            </div>
            <div className='portfolio__heading-bg'>
                <div className='portfolio__heading-bg-fade'></div>
                <div className='portfolio__heading-bg-orbit'>
                    <div className='portfolio__heading-bg-orbit-item portfolio__heading-bg-orbit-item_1'></div>
                    <div className='portfolio__heading-bg-orbit-item portfolio__heading-bg-orbit-item_2'></div>
                    <div className='portfolio__heading-bg-orbit-item portfolio__heading-bg-orbit-item_3'></div>
                    <div className='portfolio__heading-bg-orbit-item portfolio__heading-bg-orbit-item_4'></div>
                    <div className='portfolio__heading-bg-orbit-planet portfolio__heading-bg-orbit-planet_1'></div>
                    <div className='portfolio__heading-bg-orbit-planet portfolio__heading-bg-orbit-planet_2'></div>
                    <div className='portfolio__heading-bg-orbit-planet portfolio__heading-bg-orbit-planet_3'></div>
                    <img className='portfolio__heading-bg-orbit-coin' src={heading_coin} alt=""></img>

                </div>
            </div>
            <PortfolioCards />
        </div>
    );
}

export default Portfolio