import './NumbersSeparator.css';
 
function NumbersSeparator() {
  return (
    <div className='numbers-separator'>
       <div className='numbers-separator__center-box'></div>
       <div className='numbers-separator__line'></div>
    </div>
  );
}
 
export default NumbersSeparator