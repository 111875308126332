import PersonCard from '../PersonCard/PersonCard';
import './Presons.css';


import yifat_steuer from '../../../assets/images/Team/Members/yifat_steuer.webp'
import cabahub_logo from '../../../assets/images/Portfolio/logo/cabahub.png'
import shachar from '../../../assets/images/Team/Members/shachar.png'

function Presons() {
    return (
        <div className='presons'>
            <PersonCard item={{
                photo: yifat_steuer,
                name: 'Yifat Steuer',
                job: 'Finance and Corporate Advisor',
                // text: 'Lorem ipsum dolor sit amet consectetur. Dui vitae vel turpis convallis vivamus gravida aliquet pretium platea. Vestibulum malesuada adipiscing et&nbsp;elementum at&nbsp;aliquam enim.',
            }} />
            <PersonCard item={{
                photo: shachar,
                name: 'Shachar Shimony',
                job: 'Corporate Legal consulting',
                // text: 'Lorem ipsum dolor sit amet consectetur. Dui vitae vel turpis convallis vivamus gravida aliquet pretium platea. Vestibulum malesuada adipiscing et&nbsp;elementum at&nbsp;aliquam enim.',
            }} />

            <PersonCard item={{
                photo: cabahub_logo,
                name: 'Caba Hub',
                job: 'Partner, Branding, Design and Development',
                link: 'https://cabahub.com/'
                // text: 'Lorem ipsum dolor sit amet consectetur. Dui vitae vel turpis convallis vivamus gravida aliquet pretium platea. Vestibulum malesuada adipiscing et&nbsp;elementum at&nbsp;aliquam enim.',
            }} />
        </div>
    );
}

export default Presons