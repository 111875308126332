import './OurServices.css';

import img from '../../../assets/images/Services/OurServices/our-services.webp'


function OurServices() {
    return (
        <div className='our-services'>
            <div className='our-services__info'>
                <h4 className='our-services__title'>Our <br />Services</h4>
                <p className='our-services__text'>SPUTNIK identifies unique early stage businesses that requires corporate finance expertise, or&nbsp;equity capital to&nbsp;support the development and growth of&nbsp;their assets and reaching their goals, with wide industry network assisting in&nbsp;the business development growth alongside the capital growth.</p>
            </div>
            <img className='our-services__img' src={img} alt=''></img>
        </div>
    );
}

export default OurServices