import './Banner.css';

import bg from '../../../assets/images/Main/Banner/main-banner-bg.png'

function Banner() {
    return (
        <div className='banner'>
            <img className='banner__bg' src={bg} alt=""></img>
            <h2 className='banner__title'>Reaching Beyond <br/>the Limits</h2>
            <p className='banner__subtitle'>Comprehensive support for the strategic objectives of&nbsp;your business</p>
        </div>
    );
}

export default Banner