import { MgcLogoIcon } from '../../../assets/icons/icons';
import './PortfolioCards.css';

import venture_promo from '../../../assets/images/Portfolio/CardsPromo/venture-promo.webp'

import graft_logo from '../../../assets/images/Portfolio/logo/Graft.png'
import sas_logo from '../../../assets/images/Portfolio/logo/sas.png'
import acv_logo from '../../../assets/images/Portfolio/logo/acv.png'
import ts_logo from '../../../assets/images/Portfolio/logo/ts.png'
import onassis_logo from '../../../assets/images/Portfolio/logo/onasis.png'
import cabahub_logo from '../../../assets/images/Portfolio/logo/cabahub.png'

const PORTFOLIO_CARDS = [
    {
        date: '',
        title: 'MGC pharma',
        text: 'A next-generation pharmaceutical company',
        icon: MgcLogoIcon,
        isLogoSvg: true,
        tags: [
            {
                name: 'Sector',
                value: 'Pharma',
            },
        ],
        type: 'project',
    },
    {
        date: '',
        title: 'Graft Polymer',
        text: 'Graft Polymer (UK) Plc (LSE: GPL) was established in 2017 by a group of polymer technology experts and venture capitalists. ',
        icon: graft_logo,
        type: 'project',
        tags: [
            {
                name: 'Sector',
                value: 'Pharma',
            },
        ],
    },
    {
        date: '',
        title: 'Sky and Space',
        text: 'SAS is a satellite company with communication nanosatellites and software expertise. Providing communications infrastructure and services,  SAS’s nanosatellites and sophisticated software systems support space-based global communication networks.',
        icon: sas_logo,
        type: 'project',
        tags: [
            {
                name: 'Sector',
                value: 'Space',
            },
        ],
    },
    {
        date: '',
        title: 'Australian Cannabis Ventures',
        text: 'Organic indoor cannabis farm. High quality medical and recreational cannabis with fast and discreet delivery.',
        icon: acv_logo,
        type: 'project',
        tags: [
            {
                name: 'Sector',
                value: 'Pharma',
            },
        ],
    },
    {
        date: '',
        title: 'Token States',
        text: 'Token States is a blockchain-powered real estate asset tokenization platform whose aim is to provide an opportunity to invest in real estate as easily as possible.',
        icon: ts_logo,
        type: 'project',
        tags: [
            {
                name: 'Sector',
                value: 'Crypto, Real Estate',
            },
        ],
    },
    {
        type: 'med-projects-count',
    },
    {
        date: '',
        title: 'Onassis Holdings',
        text: 'Onassis Holdings Corp. is a public US corporation traded in the US OTC markets (“ONSS”). Last year, the Company transitioned its operations and is now a wellness  technology, CBD and Hemp oil holding company.',
        icon: onassis_logo,
        type: 'project',
        tags: [
            {
                name: 'Sector',
                value: 'Holdings',
            },
        ],
    },
    {
        type: 'venture',
    },
    // {
    //     date: '',
    //     title: 'Caba Hub',
    //     text: 'IT company that specialize in creating start-up MVP’s, design, brand development, web app development, cybersecurity, tech support, and consulting.',
    //     icon: cabahub_logo,
    //     type: 'project',
    //     tags: [
    //         {
    //             name: 'Sector',
    //             value: 'IT, Crypto, Business Development',
    //         },
    //     ],
    // },
]

function PortfolioCards() {
    return (
        <div className='portfolio-cards'>
            {PORTFOLIO_CARDS.map((item, i) => (
                <>
                    {item.type === 'project' ?
                        <div className='portfolio-cards__card'>
                            <p className='portfolio-cards__card-date'>{item.date}</p>

                            {item.isLogoSvg ? item.icon({ className: 'portfolio-cards__card-icon' }) : <img className="portfolio-cards__card-icon" src={item.icon} alt=""></img>}
                            <p className='portfolio-cards__card-title'>{item.title}</p>
                            <p className='portfolio-cards__card-text' dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}></p>
                            {item.tags ?
                                <div className='portfolio-cards__card-tags'>
                                    {item.tags.map((tag, i2) => (
                                        <>
                                            <div className='portfolio-cards__card-tag'>
                                                <p className='portfolio-cards__card-tag-name'>{tag.name}</p>
                                                <p className='portfolio-cards__card-tag-value'>{tag.value}</p>

                                            </div>
                                            {i2 + 1 !== item.tags.length ? <div className='portfolio-cards__card-tag-separator'></div> : null}
                                        </>
                                    ))}
                                </div>
                                : null}

                        </div>
                        : null}

                    {item.type === 'med-projects-count' ?
                        <div className='portfolio-cards__med-projects'>
                            <p className='portfolio-cards__med-projects-hint'>SPUTNIK</p>
                            <p className='portfolio-cards__med-projects-count'>4</p>
                            <p className='portfolio-cards__med-projects-text'>project <br />in the field <br />of medicine</p>
                        </div>
                        : null}

                    {item.type === 'venture' ?
                        <div className='portfolio-cards__venture'>
                            <p className='portfolio-cards__venture-hint'>SPUTNIK</p>
                            <img className='portfolio-cards__venture-img' src={venture_promo} alt=''></img>
                            <p className='portfolio-cards__venture-text'>Venture Capital</p>
                            <div className='portfolio-cards__venture-bg-circle portfolio-cards__venture-bg-circle_1'></div>
                            <div className='portfolio-cards__venture-bg-circle portfolio-cards__venture-bg-circle_2'></div>
                            <div className='portfolio-cards__venture-bg-circle portfolio-cards__venture-bg-circle_3'></div>

                        </div>
                        : null}
                </>
            ))}
        </div >
    );
}

export default PortfolioCards