import './News.css';

function News({ className }) {
    return (
        <div className={`news ${className ? className : ''}`}>
            <div className='news__heading'>
                <p className='news__title'>NEWS</p>
            </div>
        </div>
    );
}

export default News